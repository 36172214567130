

.footer {
    height:100%;
    left: 0;
    bottom: 0;
    width: 100%;
    height:100%;
    background-color: #dfb015;
}

.footerText {
    font-family: 'Times New Roman', Times, serif;
}
