 @font-face {
    font-family: 'Gill Sans Nova Ultra Bold';
    src: url('../../asset/font/GillSansNovaUltraBold.ttf') format('truetype');
  }


 
  .english-font {
    font-family: 'Gill Sans Nova Ultra Bold', sans-serif;
    font-weight: bolder;
    color: #FFCC66;
  }
