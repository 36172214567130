.navbar {
    background-color: #534D41;
}

.dropdown-menu{
    width: 10px !important; 
    background-color: #3e392f !important;
}

.dropItem {
    color: rgba(255, 255, 255, 0.55) !important; 
}

.dropItem:hover {
    color: black !important; 
}