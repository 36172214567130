td{
    font-family: 'Times New Roman', Times, serif;
}

td div p{
    font-family: 'Times New Roman', Times, serif;
}

td div p a{
    font-family: 'Times New Roman', Times, serif;
}