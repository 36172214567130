*{
  margin :0;
  padding: 0;
  font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif
}

.coverpage{
  width: 100%;
}

video{
  object-fit:cover;
  z-index: -2;
}

.content{
  position:absolute;
  width:100%;
  /* height:100%; */
  top:30%;
  display:flex;
  flex-direction: column;
  /* justify-content: center; */
  justify-content: flex-start;
  align-items: center;
  color:white;
}

.overlay{
  position:absolute;
  width:100%;
  /* height:100%; */
  left:0;
  right:0;
  background-color: rgba(0,0,0,0.4);
}

.icon {
  height:30px;
}

/* .button {
  display:inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
} */
/* .imageContainer img {
    filter: grayscale(50%) drop-shadow(16px 16px 20px #534D41) invert(5%);
  } */