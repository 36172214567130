*{
    margin :0;
    padding: 0;
  }

.AboutUsContent{
    font-family:Georgia, 'Times New Roman', Times, serif;
    font-size: x-large;
}

.Contenttitle{
  /* text-decoration: underline; */
  font-weight:600;
}
